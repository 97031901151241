$white: #FFFFFF;

@mixin excerpt($font-size: 14px, $line-height: 1.4, $lines-to-show: 2, $excerpt-bg: transparent) {
  // background: $excerpt-bg;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
//   max-height: $font-size*$line-height*$lines-to-show*1.2; /* Fallback for non-webkit */
  font-size: $font-size;
  // line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
//   @-moz-document url-prefix() {
//     overflow: hidden;
//     position: relative;
//     &:before {
//       background: $excerpt-bg;
//       bottom: 0;
//       right: 0;
//       position: absolute;
//       float: right;
//       content: '\2026';
//       width: 1.5rem;
//       margin-right: -2px;
//     }
//     &:after {
//       content: '';
//       background: $excerpt-bg;
//       position: absolute;
//       height: 50px;
//       width: 100%;
//       z-index: 1;
//     }
//   }
}

.ellipsis {
  @include excerpt($font-size: 14px, $line-height: 1.4, $lines-to-show: 2, $excerpt-bg: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(255,255,255,1) 50%));
  margin-bottom: 0;
}